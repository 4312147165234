/* HamburgerMenu.module.css */

/* The overlay background */
.hamburgerMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
  z-index: 1000; /* Ensure it overlays other elements */
}

/* When the menu is open */
.menuOpen {
  opacity: 1;
  visibility: visible;
}

/* The menu content */
.menuContent {
  position: relative;
  width: 80%; /* Adjust width as needed */
  max-width: 300px;
  height: 100%;
  background-color: white;
  padding: 2rem 1rem;
  box-sizing: border-box;
  overflow-y: auto;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
}

/* Slide-in effect when open */
.menuOpen .menuContent {
  transform: translateX(0);
}

/* Close button */
.closeButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
}

/* Navigation links */
.navLinks {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}

.navLink {
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  text-decoration: none;
  color: black;
  transition: color 0.2s ease;
}

.navLink:hover {
  color: #555; /* Adjust hover color as needed */
}

/* Media query for larger screens */
@media (min-width: 768px) {
  .menuContent {
    width: 300px; /* Fixed width for desktop */
  }
}
