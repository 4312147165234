/* Nav.module.css */

/* General Header Styles */
.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;
  background-color: transparent;
  transition: background-color 1.5s ease, color 0.5s ease;
}

/* Transparent Background */
.headerTransparent {
  background-color: transparent;
}

/* Background after Scroll */
.navBackground {
  background-color: white;
}

/* Container to hold the three columns */
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5rem; /* Adjust height as needed */
  padding: 0 2rem; /* Adjust padding as needed */
}

/* Columns */
.leftColumn,
.middleColumn,
.rightColumn {
  flex: 1;
  display: flex;
  align-items: center;
}

/* Left Column: Align Hamburger to Left */
.leftColumn {
  justify-content: flex-start;
}

/* Middle Column: Center Logo */
.middleColumn {
  justify-content: center;
}

/* Right Column: Align CTA to Right */
.rightColumn {
  justify-content: flex-end;
}

/* Hamburger Button */
.hamburgerButton {
  background: none;
  border: none;
  color: white; /* Default color */
  font-size: 1.5rem; /* Adjust as needed */
  cursor: pointer;
  transition: color 0.3s ease;
}

.hamburgerButton:hover {
  color: #ffffff; /* Hover color remains white */
}

/* Change hamburger color when navBackground is active */
.header.navBackground .hamburgerButton {
  color: black;
}

.header.navBackground .hamburgerButton:hover {
  color: #000000; /* Hover color remains black */
}

/* Logo */
.logoLink {
  display: inline-block;
}

.logoImage {
  width: 250px; /* Adjust as needed */
  transition: opacity 0.3s ease;
}

.logoImage:hover {
  opacity: 0.8;
}

/* CTA Button */
.ctaButton {
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  border: 2px solid #d1d5db; /* Gray border */
  padding: 0.5rem 1rem;
  background-color: transparent;
  color: white; /* Default color */
  cursor: pointer;
  transition: all 0.3s ease;
}

.ctaButton:hover {
  background-color: rgba(0, 0, 0, 0.3);
  border-color: #ffffff;
  color: #ffffff;
}

/* Change CTA Button color when navBackground is active */
.header.navBackground .ctaButton {
  color: black;
  border-color: black;
}

.header.navBackground .ctaButton:hover {
  background-color: rgba(
    255,
    255,
    255,
    0.3
  ); /* Adjust hover background if needed */
  border-color: #000000;
  color: #000000;
}

@media (max-width: 768px) {
  .container {
    padding: 0; /* Adjust padding as needed */
  }

  .ctaButton {
    font-size: 0.7rem;
    font-weight: 600;
    letter-spacing: 0.05em;
    padding: 0.3rem 0.4rem;
    margin-right: 10px;
  }
  .hamburgerButton {
    margin-left: 7px;
  }
  .logoImage {
    width: 120px; /* Adjust as needed */
  }
}
