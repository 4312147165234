/* src/components/Layout/Layout.module.css */

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.mainContent {
  flex: 1;
  /* Additional styles for your main content */
}
