/* Root Variables for Consistency */
:root {
  --section-margin-mobile: 2rem auto;
  --section-margin-desktop: 8rem auto;
  --section-padding-mobile: 2rem 1rem;
  --section-padding-desktop: 0;
  --image-padding-mobile: 2rem 1.5rem;
  --image-padding-desktop: 0 3rem 0 1.5rem;
  --image-padding-desktop-xl: 0 6rem 0 1.5rem;
  --button-border-color: #d1d5db;
  --button-text-color: var(--vv-text, #333);
  --button-hover-bg: var(--vv-text, #333);
  --button-hover-text: #fff;
}

/* Animation */
@keyframes animBottom {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Section Styles */
.section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; /* Mobile: Column */
  justify-content: space-around;
  align-items: center;
  margin: var(--section-margin-mobile);
  padding: 0;
  animation: animBottom 1s ease-out;
  transition: flex-direction 0.3s ease;
}

.reverse {
  /* Remove flex-direction change for mobile */
}

/* Image Container */
.imageContainer {
  width: 100%;
  height: 100%;
  padding: var(--image-padding-mobile);
}

.picture {
  width: 100%;
  height: 100%;
}
.iframe {
  width: 100%;
  height: 230px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

/* Content Container */
.contentContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

/* Text Content */
.textContent {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

/* Title */
.title {
  font-size: 2rem;
  font-style: italic;
  line-height: 1;
  width: 100%;
  letter-spacing: 0.2em;
}

/* Subtitle */
.subtitle {
  font-size: 1.5rem;
  line-height: 1;
  margin-top: 1rem;
  width: 100%;
  letter-spacing: 0.1em;
}

/* Description */
.description {
  max-width: 48rem;
  font-size: 1rem;
  line-height: 1.375;
  margin-top: 1.25rem;
  width: 100%;
  color: var(--vv-text, #333);
}

/* Button Link */
.buttonLink {
  text-decoration: none;
  width: 100%;
}

/* Button */
.button {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.1em;
  border: 2px solid var(--button-border-color);
  padding: 0.5rem 1rem;
  transition: background-color 0.3s, color 0.3s;
  background-color: transparent;
  color: var(--button-text-color);
  cursor: pointer;
}

.button:hover {
  background-color: var(--button-hover-bg);
  color: var(--button-hover-text);
  border-color: var(--button-hover-bg);
}

/* Buttons Container */
.buttonsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem; /* Space between buttons */
  margin-top: 1.5rem;
}

/* Phone Link */
.phone {
  display: inline-block;
  margin-top: 1rem;
  font-size: 1rem;
  color: var(--vv-text, #333);
  text-decoration: none;
  transition: color 0.3s;
}

.phone:hover,
.phone:focus {
  color: var(--button-hover-bg);
  text-decoration: underline;
}

/* Email Link */
.email {
  display: inline-block;
  margin-top: 0.5rem;
  font-size: 1rem;
  color: var(--vv-text, #333);
  text-decoration: none;
  transition: color 0.3s;
}

.email:hover,
.email:focus {
  color: var(--button-hover-bg);
  text-decoration: underline;
}

/* Optional: Focus Styles for Accessibility */
.button:focus,
.phone:focus,
.email:focus {
  outline: 2px dashed var(--button-hover-bg);
  outline-offset: 4px;
}

@media (min-width: 768px) {
  .section {
    flex-direction: row; /* Desktop: Row */
    height: 70vh;
    padding: var(--section-padding-desktop);
    margin: var(--section-margin-desktop);
    justify-content: space-around;
  }

  .reverse {
    flex-direction: row-reverse; /* Only affects desktop */
  }

  .imageContainer {
    padding: 32px 64px;
  }

  .iframe {
    width: 100%;
    height: 100%;
  }

  /* If you still need extra padding for larger desktops, you can add another media query or use a utility class */
  /* For simplicity, it's omitted here */

  .contentContainer {
    padding: 32px 64px;
  }

  .textContent {
    padding: 2rem;
  }

  .title {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1.25rem;
  }

  .button {
    font-size: 0.875rem;
    padding: 0.75rem 2rem;
    margin-top: 1.5rem;
  }

  .buttonsContainer {
    gap: 1.5rem;
    margin-top: 2rem;
  }

  /* Phone Link */
  .phone {
    font-size: 1.2rem;
    margin-top: 1rem;
  }

  /* Email Link */
  .email {
    font-size: 1.2rem;
    margin-top: 1rem;
  }
}

@media (max-width: 1200px) {
  .imageContainer {
    padding: 32px 24px;
  }
  .contentContainer {
    padding: 32px 24px;
  }
  .title {
    font-size: 1.5rem;
  }
  .subtitle {
    font-size: 1rem;
  }
  .description {
    font-size: 0.8rem;
  }
}
