/* GenericGallery.module.css */

.gallery {
  /* Optional: Add any additional gallery-level styles here */
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(300px, 1fr)
  ); /* Adjust minmax as needed */
  gap: 15px;
}

.item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Define fixed aspect ratio using padding-top trick */
  width: 100%;
  padding-top: 75%; /* 4:3 Aspect Ratio (adjust as needed) */
  background-color: #f0f0f0; /* Fallback background */
}

.image,
.imagePlaceholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image {
  display: block;
  transition: transform 0.3s ease;
}

.imagePlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  color: #555;
  font-size: 2em;
}

.item:hover .image {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
}

.item:hover .mask {
  opacity: 1;
}

.info {
  color: #fff;
  text-align: left;
  width: 100%;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.info:focus {
  outline: 2px solid #fff;
}

.info h2 {
  margin: 0 0 5px;
  font-size: 1.2em;
}

.info p {
  margin: 0;
  font-size: 0.9em;
}

/* Load More Button Styles */
.loadMore {
  text-align: center;
  margin-top: 40px;
}

.loadMore button {
  padding: 10px 20px;
  background: #333;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1em;
  transition: background 0.3s ease;
  border-radius: 4px;
}

.loadMore button:hover,
.loadMore button:focus {
  background: #555;
  outline: none;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .grid {
    grid-template-columns: repeat(
      auto-fill,
      minmax(200px, 1fr)
    ); /* Adjust as needed */
  }
}

@media (max-width: 600px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
