.card {
  width: 100%;
  height: 100%;
  animation: animBottom 1s ease-out;
}

.cardContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem; /* Adjust spacing as needed */
  padding: 0 1rem; /* Adjust as needed */
}

.imageContainer {
  width: 64px; /* Adjust size as needed */
  height: 64px;
  overflow: hidden;
  border-radius: 4px;
  margin-right: 0.75rem; /* Space between image and title */
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.titleLink {
  text-decoration: none;
  color: inherit;
}

.title {
  font-size: 1.25rem;
  text-transform: uppercase;
  margin: 0;
}

.textContent {
  padding: 0 1rem; /* Adjust as needed */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.description {
  font-family: "Your-Sans-Font", sans-serif; /* Replace with your sans-serif font */
  font-size: 1rem;
  margin: 0.5rem 0;
  color: #4b5563; /* Replace with your desired color */
}

.buttonLink {
  text-decoration: none;
}

.button {
  align-self: flex-start;
  padding: 0.5rem 1rem;
  font-family: "Poppins", sans-serif; /* Replace with your button font */
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border: 2px solid #d1d5db; /* Replace with your border color */
  background-color: #ffffff; /* Replace with your background color */
  color: #1f2937; /* Replace with your text color */
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  cursor: pointer;
}

.button:hover {
  background-color: #1f2937; /* Replace with your hover background color */
  color: #ffffff; /* Replace with your hover text color */
  border-color: #1f2937; /* Replace with your hover border color */
}

@keyframes animBottom {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 375px) {
  .title {
    font-size: 0.9rem;
    text-transform: uppercase;
    margin: 0;
  }
  .description {
    font-size: 0.8rem;
  }
  .header {
    padding: 0;
  }
  .textContent {
    padding: 0;
  }
}
