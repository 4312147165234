.flatSection {
  position: relative;
  width: 100%;
  height: 24rem; /* Default height: h-96 (Tailwind's h-96 is 24rem) */
  margin: 4rem 0; /* Default margin: my-16 (Tailwind's my-16 is 4rem) */
  overflow: hidden;
  animation: animBottom 1s ease-out;
}

.imageContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5),
    transparent
  );
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.content {
  max-width: 48rem; /* Equivalent to max-w-3xl (48rem) */
  padding: 2rem; /* Equivalent to p-8 */
  text-align: left;
}

.title {
  font-size: 1.5rem; /* Default: text-2xl */
  font-style: italic;
  text-transform: uppercase;
  line-height: 1;
  margin-top: 1rem;
}

.description {
  font-size: 1rem; /* Default: text-base */
  line-height: 1.75;
  margin-top: 1.25rem;
  color: #f3f4f6; /* Example color: replace with your `twTextColor` */
}

.button {
  display: inline-block;
  margin-top: 1.25rem;
  padding: 0.5rem 1rem; /* Equivalent to px-4 py-2 */
  font-size: 0.75rem; /* text-xxs */
  font-weight: 600; /* font-semibold */
  letter-spacing: 0.05em; /* tracking-xl */
  text-transform: uppercase;
  border: 2px solid #d1d5db; /* border-gray-300 */
  background-color: #ffffff; /* bg-white */
  color: #1f2937; /* text-vv-text: replace with your color */
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
}

.button:hover {
  background-color: #1f2937; /* hover:bg-vv-text: replace with your color */
  color: #ffffff; /* hover:text-white */
  border-color: #1f2937; /* border-vv-text: replace with your color */
}

/* Animation */
@keyframes animBottom {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Height Classes (Optional) */
@media (min-width: 768px) {
  .flatSection {
    height: 24rem; /* md:h-96 */
  }
}

@media (min-width: 1280px) {
  .flatSection {
    height: 28rem; /* xl:h-112 */
  }
}

@media (min-width: 1536px) {
  .flatSection {
    height: 30rem; /* xxl:h-120 */
  }
}

@media (min-width: 1920px) {
  .flatSection {
    height: 32rem; /* xxxl:h-128 */
  }
}

@media (min-width: 1024px) {
  .overlay {
    padding-left: 7rem; /* lg:px-28 */
    padding-right: 7rem;
  }
}
