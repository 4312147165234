/* Hero Section Styles */
.heroSection {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 100px 20px;
  text-align: center;
  color: var(--neutral-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: var(--neutral-dark);
  height: 800px;
}

/* Add a homeVariant class for the homepage */
.homeVariant {
  min-height: 75vh; /* Full height for homepage */
}

/* Default variant for other pages */
.defaultVariant {
  min-height: 40vh; /* Shorter height for other pages */
}

/* Background Overlay for better text readability */
.heroSection::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1); /* Dark overlay */
  z-index: 0;
}

/* Hero Content */
.heroContent {
  max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
}

/* Hero Title */
.heroSection h1 {
  font-size: 48px;
  margin-bottom: 20px;
  letter-spacing: -0.02rem;
  line-height: 3.75rem;
}

/* Hero Subtitle */
.heroSection p {
  font-size: 20px;
  margin-bottom: 40px;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  line-height: 1.6;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
}

/* CTA Button */
/* Conditionally apply margin-top based on presence of CTA */
.ctaButton {
  background-color: var(--primary-color);
  color: var(--neutral-white);
  padding: 15px 30px;
  font-size: 18px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 2px;
  transition: background-color 0.3s ease, transform 0.2s ease,
    box-shadow 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-decoration: none;
}

.ctaButton:hover {
  background-color: var(--accent-color);
  transform: translateY(-5px);
}

/* Responsive Styling */
@media (max-width: 768px) {
  .homeVariant {
    min-height: 75vh; /* Full height on small screens for homepage */
  }

  .heroContent {
    max-width: 100%; /* Make sure the content fits better on smaller screens */
  }

  .heroSection p {
    font-size: 1.2rem; /* Reduce subtitle font size for better readability */
    margin-bottom: 20px; /* Adjust spacing */
    max-width: 100%; /* Allow full width on smaller screens */
  }

  .ctaButton {
    padding: 12px 25px;
    font-size: 16px;
  }
}

@media (max-width: 1023px) {
  .heroSection {
    height: 95vh;
  }
  .heroSection h1 {
    font-size: 3rem; /* Reduce font size for mobile */
    line-height: 3.75rem;
  }
  .heroSection p {
    font-size: 2rem; /* Reduce subtitle font size for better readability */
    margin-bottom: 20px; /* Adjust spacing */
    max-width: 100%; /* Allow full width on smaller screens */
  }
}
@media (min-width: 1024px) {
  .heroSection h1 {
    font-size: 7rem;
    line-height: 7rem;
  }

  .heroSection p {
    font-size: 2.7rem;
    max-width: 1200px;
  }

  .ctaButton {
    padding: 18px 40px;
    font-size: 1.7rem;
  }
}
