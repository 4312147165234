/* src/components/TripleCardSection/TripleCardSection.module.css */

.container {
  width: 100%;
  max-width: 1440px; /* Equivalent to max-w-9xl */
  margin: 0 auto;
}

.cardList {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  gap: 4rem; /* Default gap: gap-16 */
  padding: 0 2rem; /* Default padding: px-8 */
  list-style-type: none;
}

@media (min-width: 640px) {
  .cardList {
    padding-left: 3rem; /* sm:px-12 */
    padding-right: 3rem;
  }
}

@media (min-width: 768px) {
  .cardList {
    flex-direction: row;
    gap: 1.5rem; /* md:gap-6 */
  }
}

@media (min-width: 1280px) {
  .cardList {
    gap: 4rem; /* xl:gap-16 */
  }
}

@media (min-width: 1536px) {
  .cardList {
    gap: 6rem; /* xxl:gap-24 */
  }
}

@media (min-width: 1920px) {
  .cardList {
    padding-left: 0; /* xxl:px-0 */
    padding-right: 0;
  }
}
